import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

function Packages() {
  return (
    <Flex
      justify="space-between"
      gap={{ base: 1, md: 5 }}
      align="flex-start"
      mt="20px"
    >
      <Box minW={"50px"}>
        <Text
          color="#412671"
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="600"
        >
          Basic
        </Text>
        <Text
          fontSize={{ base: "13px", md: "16px" }}
          fontWeight="600"
          color="#52e1de"
        >
          $25-$99
        </Text>
        <Text fontSize={{ base: "10px", md: "12px" }} fontWeight="500">
          6% Monthly
        </Text>
      </Box>
      <Box border="1px solid #412671" height="65px"></Box>
      <Box minW={"50px"}>
        <Text
          color="#412671"
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="600"
        >
          Standard
        </Text>
        <Text
          fontSize={{ base: "13px", md: "16px" }}
          fontWeight="600"
          color="#52e1de"
        >
          $100-$199
        </Text>
        <Text fontSize={{ base: "10px", md: "12px" }} fontWeight="500">
          8% Monthly
        </Text>
      </Box>
      <Box border="1px solid #412671" height="65px"></Box>
      <Box minW={"50px"}>
        <Text
          color="#412671"
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="600"
        >
          Super
        </Text>
        <Text
          fontSize={{ base: "13px", md: "16px" }}
          fontWeight="600"
          color="#52e1de"
        >
          $200-$499
        </Text>
        <Text fontSize={{ base: "10px", md: "12px" }} fontWeight="500">
          10% Monthly
        </Text>
      </Box>
      <Box border="1px solid #412671" height="65px"></Box>
      <Box minW={"50px"}>
        <Text
          color="#412671"
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="600"
        >
          Premium
        </Text>
        <Text
          fontSize={{ base: "13px", md: "16px" }}
          fontWeight="600"
          color="#52e1de"
          minW={"55px"}
        >
          ≥ $500
        </Text>
        <Text fontSize={{ base: "10px", md: "12px" }} fontWeight="500">
          12% Monthly
        </Text>
      </Box>
    </Flex>
  );
}

export default Packages;
