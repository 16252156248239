import { Box, Button, Flex, Heading, Image } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useSignMessage } from "wagmi";

function Navbar() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  return (
    <Box px="15px" py="10px" borderBottom={"1px solid #e0e0e0"}>
      <Flex align="center" justify="space-between">
        <Image h={["25px", "45px"]} src="logo.svg" />
        <Button
          background="#52e1de"
          borderRadius="30px"
          fontSize={["12px", "16px"]}
          fontWeight="500"
          h={["30px", "45px"]}
          // w={['90px', '110px']}
          onClick={() => {
            open();
          }}
        >
          {isConnected
            ? address?.replace(address?.slice(6, 37), "***")
            : "Connect"}
        </Button>
      </Flex>
    </Box>
  );
}

export default Navbar;
